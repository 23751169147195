<template>
  <div class="container px-2 mx-auto dark:bg-gray-900">
    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    >
    </loading>
    <div class="flex flex-row space-x-6 rounded-t-xl">
      <div class="flex items-center justify-start">
        <h2
          class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200 text-left"
        >
          Libro Diario
        </h2>
        <button
          title="Exportar Tabla"
          v-on:click="exportarExcel()"
          class="py-2 px-2 ml-2 mr-6 font-medium leading-5 text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-md active:bg-blue-600 hover:bg-green-400 focus:outline-none focus:shadow-outline-purple"
        >
          <i class="icon-download" aria-hidden="true"></i>
        </button>
      </div>
    </div>

    <nav class="sticky container mx-auto w-full top-0">
      <div
        class="flex flex-row flex-shrink-0 items-center justify-start shrink-0 text-sm font-bold uppercase rounded-t-lg bg-gradient-to-t from-blue-600 to-blue-500 text-white"
      >
        <div class="flex items-center justify-center w-14 h-10 px-1">
          <span>N.</span>
        </div>
        <div class="flex items-center w-20 h-10 px-1">
          <span>Asiento</span>
        </div>
        <div class="flex items-center w-24 h-10 px-1">
          <span>Fecha</span>
        </div>
        <div class="flex items-center flex-1 w-32 h-10 px-1">
          <span>Cuenta</span>
        </div>
        <div class="flex items-center flex-1 w-44 h-10 px-1">
          <span>Descripción</span>
        </div>
        <div class="flex items-center w-40 h-10 px-1">
          <span>Referencias</span>
        </div>
        <div class="flex items-center w-24 h-10 px-1">
          <span>Debito</span>
        </div>
        <div class="flex items-center w-24 h-10 px-1">
          <span>Crédito</span>
        </div>
      </div>
    </nav>

    <div class="overflow-hidden border-l-2 border-r-2">
      <div
        class="flex flex-shrink-0 justify-start text-sm hover:bg-blue-100 duration-150"
        v-for="(item, index) in objAccount"
        :key="index"
      >
        <div
          class="flex items-center pl-3 w-14 h-6 px-1 border-b border-gray-300"
        >
          <span>
            <router-link
              :to="{
                name: 'NumberAccTrans',
                params: {
                  url: 'entrynumber',
                  number: item.accounting_entry_code,
                },
              }"
              class="border-b-2 border-blue-300 hover:border-blue-500 transition ease-in-out duration-200"
            >
              {{ index + 1 }}
            </router-link>
          </span>
        </div>
        <div class="flex items-center w-20 h-6 px-1 border-b border-gray-300">
          <router-link
            :to="{
              name: 'NumberAccTrans',
              params: {
                url: 'entrynumber',
                number: item.accounting_entry_code,
              },
            }"
            class="border-b-2 border-blue-300 hover:border-blue-500 transition ease-in-out duration-200"
          >
            {{ item.accounting_entry_code }}
          </router-link>
        </div>
        <div
          class="flex items-center text-left w-24 h-6 px-1 flex-wrap overflow-y-auto border-b border-gray-300"
        >
          <span>{{ item.transactionDate }}</span>
        </div>
        <div
          class="flex items-center text-left flex-1 w-32 h-6 px-1 flex-wrap overflow-y-auto border-b border-gray-300"
        >
          <span>{{ item.accountCode }} - {{ item.accountName }}</span>
        </div>
        <div
          class="flex items-center text-left flex-1 w-40 h-6 px-1 flex-wrap overflow-y-auto border-b border-gray-300"
        >
          <span>{{ item.description }}</span>
        </div>
        <div
          class="flex items-center text-left w-44 h-6 px-1 flex-wrap overflow-y-auto border-b border-gray-300"
        >
          <span
            >( {{ item.reference_type }}: {{ item.reference }}) P:{{
              item.auxiliary
            }}</span
          >
        </div>
        <div class="flex items-center w-24 h-6 px-1 border-b border-gray-300">
          <span>{{
            item.debit > 0 ? formatNumber(item.debit, ",", "$", true) : ""
          }}</span>
        </div>
        <div class="flex items-center w-24 h-6 px-1 border-b border-gray-300">
          <span>{{
            item.credit > 0 ? formatNumber(item.credit, ",", "$", true) : ""
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
// import Swal from 'sweetalert2'
import { mapState } from "vuex";
export default {
  name: "listAcountEntry",
  components: {
    Loading,
  },
  props: {},
  data() {
    return {
      URL: process.env.VUE_APP_URL_ACC,
      objAccount: [],
      isLoading: false,
      fullPage: true,
      currentDate: moment().format("YYYY-MM-DD"),
    };
  },
  computed: {
    ...mapState(["user", "year"]),
  },
  mounted() {
    this.listAcountEntry();
  },

  methods: {
    onCancel() {
      console.log("User cancelled the loader.");
    },
    formatNumber(number, separator = ",", symbol = "$", showSymbol = false) {
      number = parseFloat(number);
      number = number.toFixed(2);
      if (separator == ",") {
        let montoNuevo = number
          .toString()
          .replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, "$1.$2")
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
        if (showSymbol) {
          return `${symbol}${montoNuevo}`;
        } else {
          return `${montoNuevo}`;
        }
      } else {
        let montoNuevo = number
          .toString()
          .replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, "$1,$2")
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        if (showSymbol) {
          return `${symbol}${montoNuevo}`;
        } else {
          return `${montoNuevo}`;
        }
      }
    },
    async listAcountEntry() {
      this.isLoading = true;
      const URL = `${this.URL}accountingJournal`; //se utiliza la misma ruta, que toma las ultimas transacciones del mayor
      // y luego se invierten
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`
        },
      };
      let params = {
        company_id: this.user.companyId,
        company: this.user.companyId,
        year: this.year,
        // company_id : 5,
      };
      // try {
      let rsAccount = await Axios.post(URL, params, fetchConfig);
      console.log(rsAccount);
      // return
      let res = rsAccount.data.data;
      // res.reverse()
      this.objAccount = res;
      this.isLoading = false;
    },
    async exportarExcel() {
      this.isLoading = true;
      const URL = `${this.URL}accountingJournal`; //se utiliza la misma ruta, que toma las ultimas transacciones del mayor
      // y luego se invierten
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`
        },
      };
      let params = {
        company_id: this.user.companyId,
        company: this.user.companyId,
        year: this.year,
        // company_id : 5,
      };
      // try {
      let rsAccount = await Axios.post(URL, params, fetchConfig);

      // return
      let res = rsAccount.data.data;
      console.log("rs");
      console.log(res);
      // res.reverse()

      let datos = res.map((item) => {
        let ASIENTO = item.accounting_entry_code;
        let CUENTA = item.accountCode;
        let FECHA = item.transactionDate;
        let reference_type = item.reference_type ? item.reference_type : "";
        let reference = item.reference ? item.reference : "";
        let REFERENCIA = `${reference_type} ${reference}`;
        let DESCRIPCION = item.description;
        let DEBITO = 0.0;
        let CREDITO = 0.0;
        const formatter = new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',});

        if (item.debit == 0 || item.debit == 0.000) {
          DEBITO = "";
        }else{
          DEBITO =  formatter.format(item.debit);
        }
        if (item.credit == 0 || item.credit == 0.000) {
          CREDITO = "";
        }else{
        CREDITO =  formatter.format(item.credit);
        }
        //let balanceD = 0.00;
        //let balanceC = 0.00;
        // if(item.accountNature==`D`){
        // 	//D=D-C
        // 	let result = item.debit-item.credit
        // 	if(result>0){
        // 		balanceD = result
        // 	}else{
        // 		balanceC = result*(-1)
        // 	}
        // }else{
        // 	//C=C-D
        // 	let result = item.credit-item.debit
        // 	if(result>0){
        // 		balanceC = result
        // 	}else{
        // 		balanceD = result*(-1)
        // 	}
        // }
        // if(balanceD<1){balanceD=''}
        // if(balanceC<1){balanceC=''}
        // // Create our number formatter.
        // const formatter = new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',});
        // // These options are needed to round to whole numbers if that's what you want.
        //           //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //           //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        // if(balanceD>0){balanceD = formatter.format(balanceD)}
        // if(balanceC>0){balanceC = formatter.format(balanceC)}
        // balanceD = balanceD.replace(/,/g, "").replace('.', ',').replace('$', '')
        // balanceC = balanceC.replace(/,/g, "").replace('.', ',').replace('$', '')
        return {
          ASIENTO_: ASIENTO,
          FECHA_: FECHA,
          CUENTA_: CUENTA,
          REFERENCIA_: REFERENCIA,
          DESCRIPCION_: DESCRIPCION,
          DEBITO_: DEBITO,
          CREDITO_: CREDITO,
        };
      });
      console.log("datos");
      console.log(datos);
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      import("./Export2Excel").then((excel) => {
        const OBJ = datos; // data json
        const multiheader = [
          ["", "", "", "", "", "", ""],
          ["", "", "", "LIBRO DIARIO", "", "", ""], // header in Excel
          ["", "", "", "", "", "", ""],
        ];
        const header = [
          "ASIENTO",
          "FECHA",
          "CUENTA",
          "DESCRIPCIÓN",
          "REFERENCIA",
          "DÉBITO",
          "CRÉDITO",
        ];
        const Field = [
          "ASIENTO_",
          "FECHA_",
          "CUENTA_",
          "DESCRIPCION_",
          "REFERENCIA_",
          "DEBITO_",
          "CREDITO_",
        ]; // Field for map with ob data json
        const Data = this.FormatJSon(Field, OBJ); // data mapped field and obj data
        excel.export_json_to_excel({
          multiHeader: multiheader,
          data: Data,
          header: header,
          sheetName: moment().format("YYYY-MM-DD"),
          filename: `Libro Diario_${moment().format("YYYY-MM-DD")}`,
          autoWidth: true,
          bookType: "xlsx",
        });
      });
      this.objAccount = res;
      this.isLoading = false;
    },
    FormatJSon(FilterData, JsonData) {
      // Funtion Format OBJ
      return JsonData.map((v) =>
        FilterData.map((j) => {
          return v[j];
        })
      );
    },
  },
  watch: {},
};
</script>

<style></style>
